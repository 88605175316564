@import "bootstrap_variables";
@import url("https://use.typekit.net/jyv7fph.css");

body {
  font-family: "Sofia-pro", sans-serif !important;
}

h1,
h2,
h3,
h4 {
  font-family: "Sofia-pro", sans-serif;
  font-weight: bold !important;
}

.how-it-works-title {
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 0rem;
}

.btn {
  min-width: 175px;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}

button.float-end.reset-button.btn.btn-primary {
  min-width: 100px;
  background: transparent;
  color: inherit;
  font-weight: bold;
  border: solid 2px $primary;
}

// /* override styles when printing */
// @media print {
//   body {
//     margin: 0;
//     color: #000;
//     background-color: #000;
//   }
// }
