.search-form {
  font-family: "Sofia-pro", sans-serif;
  .form-select,
  .select-header {
    border: solid 2px red;
    background-color: transparent;
    color: inherit;
    min-width: 100%;
    text-align: left;
    font-weight: bold;
    &::after {
      display: none;
      position: absolute;
      right: 15px;
      top: 18px;
    }
    svg.svg-inline--fa.fa-chevron-down {
      position: absolute;
      right: 15px;
      top: 13px;
    }
  }

  .continent.form-check,
  .default.form-check {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .country.form-check {
    margin-left: 1.5rem;
    margin-right: 0.5rem;
  }

  .continent.form-check,
  .default.form-check {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .country.form-check {
    margin-left: 1.5rem;
    margin-right: 0.5rem;
  }

  .countries-dropdown-list {
    .dropdown-menu.show {
      width: max-content;
      max-height: 300px;
      overflow-y: scroll;
    }
  }

  .dropdown-menu.show {
    // min-width: 100%;
    width: max-content !important;
    max-height: 500px;
    overflow-y: auto;
  }

  .dropdown-list-column {
    border-left: solid 2px black;
  }

  // .dropdown-item {
  //   width: auto;
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   .form-check-input {
  //     margin-left: -10px;
  //     margin-right: 8px;
  //     border-radius: 0 !important;
  //   }
  //   // Set indent for all the continents
  //   #multiselect-countries-0,
  //   #multiselect-countries-1,
  //   #multiselect-countries-3,
  //   #multiselect-countries-4,
  //   #multiselect-countries-5,
  //   #multiselect-countries-6,
  //   #multiselect-countries-8 {
  //     margin-left: -1.5em;
  //   }
  // }

  ul.list {
    list-style: none;
    padding: 0;
    max-height: 250px;
    overflow-y: auto;
    li {
      display: flex;
    }
    .index-color {
      // This is just a fallback, we set the color inline on the element for each country
      background-color: hsl(210, 14%, 53%);
      min-width: 15px;
      max-width: 15px;
      min-height: 15px;
      max-height: 15px;
      margin: 0.3rem 1rem auto 0.5rem;
    }
  }
}
