@import "../../bootstrap_variables.scss";

.toggle-wrapper {
  background-color: #f4f4f3;
  padding: 1rem;
  border-radius: 0.5rem;

  .toggle-title {
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 0rem;
  }

  .btn-group {
    border: solid 2px $primary;
    padding: 0.2rem;
    border-radius: 12px;
}

  label.toggle-view-button.btn.btn-secondary {
    border-radius: 0.5rem !important;
    min-width: auto;
    background: transparent;
    color: inherit;
    font-weight: 400;
    font-style: normal;
    border: none;
  }
  .btn-check:checked + .btn {
    background: $primary !important;
    color: white !important;
  }
}
