table {
  font-family: "Sofia-pro", sans-serif;
  font-size: 14px;
}
.report-table-title {
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
}

.capacity-report-table-wrapper {
  position: relative;
  max-height: 550px;
  overflow-y: scroll;
  background: #f5f5f5;
  border: solid 0.5rem whitesmoke;

  .country-row {
    span {
      background: #646464 !important;
      color: white;
      font-weight: bold;
    }
  }

  /////////////////////////////////////////////////////////////////////////
  span.td-inner-wrapper-totals.dfis {
    background: #d2d2d2 !important;
  }
  span.td-inner-wrapper-totals.gec {
    background-color: #c1c1c1 !important;
  }
  span.td-inner-wrapper-totals.iips {
    background-color: #b8b8b8 !important;
  }
  // .capacity-report-table-wrapper .td-inner-wrapper-totals:nth-child(3) {
  //   display: none !important;
  // }

  .table-responsive {
    overflow: unset;
  }

  thead {
    position: sticky;
    top: 0;
  }

  .capacity-report-table {
    position: relative;
    margin-bottom: 0;
  }

  .table-programmes-row {
    border-bottom: transparent;
    border-left: solid 1px;
  }

  .table-gender-row {
    // border-bottom: solid 1px #343434;
    background-color: whitesmoke;
    border-left: solid 1px;
    .full-height-col {
      min-height: 60px;
      display: grid;
      align-content: center;
      border-bottom: solid 1px #343434;
      border-right: solid 1px #343434;
    }
    .td-inner-wrapper {
      font-size: 12px !important;
      font-weight: 300;
      padding: 0 !important;
      color: black;
      border: none;
    }
  }

  .border-left {
    border-left: solid 1px;
  }

  .border-right {
    border-right: solid 1px;
  }

  .table-header-col {
    background: white;
    padding: 0 0.5rem !important;
    vertical-align: middle;
    width: 110px;
    &.attendees {
      background: #343434;
      color: white;
      border-left: solid 1px #343434;
    }
    &.country {
      width: 120px;
    }
  }

  tbody {
    border-left: solid 1px #343434;
    border-right: solid 1px #343434;
    border-bottom: solid 1px #343434;
    tr {
      border-color: none;
      border-style: none !important;
      vertical-align: middle;
    }
  }

  .row-title {
    background: #343434;
    color: white;
    border-left: solid 1px #343434;
  }

  .table > :not(caption) > * > * {
    border-bottom-width: 0;
    padding: 0;
  }

  .td-inner-wrapper {
    padding: 0rem 0.5rem 0 0.5rem !important;
    border-bottom: solid 2px white;
    border-top: solid 2px white;
    display: block;
    background: whitesmoke;
  }

  .td-inner-wrapper-totals {
    padding: 0rem 0.5rem 0 0.5rem !important;
    border-bottom: solid 2px white;
    border-right: solid 2px white;
    border-top: solid 2px white;
    border-left: solid 2px white;
    display: block;
    background-color: #e8e7e7;
  }

  .td-inner-wrapper-last {
    padding: 0rem 0.5rem 0 0.5rem !important;
    border-bottom: solid 1px #343434;
    border-top: 0px;
    display: block;
  }

  .td-inner-wrapper-last-totals {
    // padding: 0rem 0.5rem 0 0.5rem !important;
    // border-bottom: solid 1px #343434;
    // border-right: solid 2px white;
    // border-top: 0px;
    // border-left: solid 2px white;
    display: block;
    background-color: #81b1db;
  }

  .gender-totals-row {
    .row-title {
      .td-inner-wrapper {
        padding: 0rem 0 0 0.5rem !important;
        border-bottom: solid 2px white;
        border-top: solid 2px #343434;
        display: block;
        background: #343434;
        width: 120px;
      }
    }

    .full-height-col {
      min-height: 46px;
      display: grid;
      align-content: center;
      background-color: #f1dedf;
      border-bottom: solid 2px white;
      border-right: solid 2px white;
      border-top: solid 2px white;
      border-left: solid 2px white;
    }

    .td-inner-wrapper-totals {
      border-top: 0px;
      background-color: #f1dedf;
      border: none;
    }
  }

  .gender-percentage-row {
    .row-title {
      .td-inner-wrapper {
        padding: 0rem 0 0 0.5rem !important;
        border-bottom: solid 2px white;
        border-top: solid 2px #343434;
        display: block;
        background: #343434;
      }
    }

    .full-height-col {
      min-height: 46px;
      display: grid;
      align-content: center;
      background-color: #fae56f;
      border-bottom: solid 2px white;
      border-right: solid 2px white;
      border-top: solid 2px white;
      border-left: solid 2px white;
    }

    .td-inner-wrapper-totals {
      border-top: 0px;
      background-color: #fae56f;
      border: none;
    }
  }

  .faded-overlay {
    position: sticky;
    bottom: 0px;
    width: 100%;
    height: 30px;
    background: linear-gradient(360deg, whitesmoke, transparent);
  }

  .course-percentage-row {
    .full-height-col {
      min-height: 65px;
      display: grid;
      padding: 0rem 0.5rem 0 0.5rem !important;
      border-bottom: solid 1px #343434;
      border-right: solid 2px white;
      border-top: 0px;
      border-left: solid 2px white;
      background-color: #81b1db;
      align-content: center;
    }
  }
}

@media print {
  .capacity-report-table-wrapper {
    position: relative;
    max-height: unset !important;
    overflow-y: unset !important;
    background: #f5f5f5;
    border: solid 0.5rem whitesmoke;
  }
  #print-pdf-settings-modal {
    display: none;
  }
  .modal-backdrop.show {
    display: none;
  }
}
