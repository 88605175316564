$below-100: #ed4e4e;
$below-200: #e8112d;
$below-300: #c20e25;
$below-400: #90102a;
$over-400: #63102a;

.capacity-report-map {
  position: relative;
  margin-top: 2rem;
  overflow-x: unset;
  @media only screen and (max-width: 1000px) {
    overflow-x: scroll;
  }
  img{
    max-width: 1000px;
    margin: 0 auto;
  }
  .country-legend {
    position: absolute;
    bottom: 0;
    p {
      background: #ffffffa3;
    }
  }
  .key-wrapper {
    display: flex;
    max-width: 340px;
    p {
      margin-bottom: 0.5rem;
    }
    .key {
      width: 50px;
      height: 20px;
      margin-right: 1rem;
      &.below-100 {
        background: $below-100;
      }
      &.below-200 {
        background: $below-200;
      }
      &.below-300 {
        background: $below-300;
      }
      &.below-400 {
        background: $below-400;
      }
      &.over-400 {
        background: $over-400;
      }
    }
  }

  path {
    fill: #a3a3a3;
  }

  // Countries with less than 100 participants
  #LY,
  #BO,
  #PY,
  #GT,
  #JM,
  #HT,
  #GN,
  #CI,
  #CM,
  #SS,
  #UG,
  #RW,
  #BI,
  #CH,
  #LA {
    fill: $below-100;
  }

  // Countries with less than 200 participants
  #TJ,
  #NP,
  #BD,
  #RU,
  #AR,
  #AO,
  #CD,
  #NE,
  #ET,
  #AE,
  #MD,
  #GR,
  #RS,
  #AL,
  #MK {
    fill: $below-200;
  }

  // Countries with less than 300 participants
  #CF,
  #TD,
  #ID,
  #PH,
  #MM,
  #GB,
  #UZ,
  #KG,
  #AF,
  #SL,
  #GW,
  #GH,
  #TG {
    fill: $below-300;
  }

  // Countries with less than 400 participants
  #CA,
  #MR,
  #DZ,
  #MA,
  #EG,
  #SD,
  #BW,
  #YE,
  #IN,
  #TH,
  #KH,
  #SE,
  #FI,
  #FR,
  #ES,
  #IT,
  #TR,
  #SY,
  #CL,
  #CO,
  #AU {
    fill: $below-400;
  }

  // Countries over 400 participants
  #BT,
  #NZ,
  #SB,
  #FJ,
  #PG,
  #PT,
  #US,
  #VN,
  #MX,
  #PE,
  #BR,
  #ML,
  #SN,
  #LR,
  #BF,
  #BJ,
  #NG,
  #GA,
  #CG,
  #NA,
  #ZA,
  #MZ,
  #ZW,
  #ZM,
  #TZ,
  #KE,
  #SO,
  #MG,
  #TN,
  #SA,
  #IQ,
  #JO,
  #IL,
  #LB,
  #IR,
  #PK,
  #CN,
  #KZ,
  #NO,
  #DK,
  #DE,
  #NL,
  #BE,
  #AT,
  #LU {
    fill: $over-400;
  }
}
