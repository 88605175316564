.report-summary-container {
  .summary-title {
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
  }

  .report-summary {
    background-color: #f5f5f5;
    padding: 0.5rem;
  }

  .country-representation,
  .program-representation,
  .sector-representation,
  .gender-representation {
    margin-bottom: 0;
    font-weight: bold;
  }
}
